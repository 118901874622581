<a
  
  mat-list-item
  (click)="onItemSelected(item)"
  [ngClass]="{ active: isActive, expanded: expanded }"
  class="menu-list-item"
>
  <mat-icon class="routeIcon">{{ item.iconName }}</mat-icon>
  <span class="navigation-item-label">{{ item.displayName }}</span>
  <span class="navigation-item-expand-icon" fxFlex *ngIf="item.children && item.children.length">
    <span fxFlex></span>
    <mat-icon [@indicatorRotate]="expanded ? 'expanded' : 'collapsed'">
      expand_more
    </mat-icon>
  </span>
</a>
<div *ngIf="expanded">
  <app-menu-list-item class="isParentExpanded" *ngFor="let child of item.children" [item]="child" [depth]="depth + 1"> </app-menu-list-item>
</div>
