import { Injectable, InjectionToken, Inject } from '@angular/core';
import { CommonModule, AsyncPipe } from '@angular/common';
import { SelectModel } from '../models/select-model';
import { IndividualTypeString, IndividualType } from '../models/enums/individual-type.enum';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { OperationStatus } from '../models/enums/operation-status.enum';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EnumTranslateService {
  // constructor(@Inject(ENUM_TRANSLATE_DICTIONARY) private translationDictionary: Map<any, any>) {}
  constructor(private translatePipe: TranslateService) {}

  // async enumsDropdownList(type): SelectModel<any>[] {
  //   var result = Object.keys(type).map(async value => {
  //     let translation = await this.translate(value, type);
  //     if (translation == null) {
  //       translation = value.toString();
  //     }

  //     return new SelectModel<any>(value, translation);
  //   });
  //   const val= await Promise.all(result);
  //   return val
  // }

  async translate(value: any, type: any) {
    if (value == null) {
      return value;
    }
    const val = type[value];
    const result = await this.translatePipe
      .get(this.getPrefix(type) + val)
      .pipe(first())
      .toPromise();

    return result;
  }

  private getPrefix(type) {
    if (type == null) {
      throw new Error('Type must be defined');
    }

    const set = enumsPrefixes.get(type);
    if (set == null) {
      throw new Error(`Type is not registered`);
    }

    return `Enums.${set}.`;
  }
}

export const enumsPrefixes = new Map<any, string>([
  [IndividualType, 'IndividualType'],
  [OperationStatus, 'OperationStatus']
]);
