import { EventEmitter, Injectable, Output, Directive } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { WidgetsModule } from '../widgets.module';

@Directive()
@Injectable({ providedIn: 'root' })
export class NavService {
  public appDrawer: any;
  public currentUrl = new BehaviorSubject<string>(undefined);
  @Output() change: EventEmitter<boolean> = new EventEmitter();
  @Output() refresh: EventEmitter<null> = new EventEmitter();

  constructor(private router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl.next(event.urlAfterRedirects);
      }
    });
  }

  retractAllTabs() {
    this.change.emit();
  }

  refreshAllTabs(){
    this.refresh.emit();
  }

  public closeNav() {
    this.appDrawer.close();
  }

  public openNav() {
    this.appDrawer.open();
  }
}
