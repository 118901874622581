export enum AppPermission {
  NotSet = 0,
  UserRead = 40,
  UserChange = 41,
  RoleRead = 50,
  RoleChange = 51,
  ClientRead = 60,
  ClientChange = 61,
  ClientGiRead = 65,
  ClientServiceRead = 66,
  ClientGiEdit = 67,
  ClientServiceEdit = 68,
  ResourceRead = 70,
  ResourceChange = 71,
  OrderRead = 80,
  OrderChange = 81,
  OrderReadForAllCompanies = 82,
  OrderReadOwn = 83,
  OrderReadOwnCompany = 84,
  OrderAssignEmployee = 85,
  OrderAssignContractor = 86,
  OrderTypesEdit = 90,
  OrderWorkflowsEdit = 91,
  OrderStatusesEdit = 92,
  OrderActionTypesEdit = 93,
  CreateEmployee = 100,
  ReadEmployee = 101,
  UpdateEmployee = 102,
  DeleteEmployee = 103,
  ReadEmployeesForCompany = 104,
  CanChangeOrderStatus = 110,
  CanChangeOrderStatusFull = 111,
  OrderActionRead = 120,
  OrderActionChange = 121,
  CompanyNotesEdit = 150,
  CompanyContactEdit = 151,
  ContractContract = 200,
  UpdateDictionary = 201,
  UpdateBuissnessPartner = 202,
  BusinessObjectRead = 500,
  SimsRead = 700,
  DevicesRead = 750,
  ContractRead = 1000,
  OldPermissionNotUsed = 1000,
  ContractCreate = 1001,
  RodoContractRead = 1010,
  ContractGroupsEdit = 1101,
  ContractParametersEdit = 1102,
  ContractCostsEdit = 1103,
  ContractUnitProvidingServiceEdit = 1104,
  ContractContractorRepresentativesEdit = 1105,
  ContractContractorRodoRepresentativesEdit = 1106,
  InvoiceRead = 2100,
  InvoiceChange = 2101,
  ClientsRead = 2102,
  ClientsChange = 2103,
  ReadOwnReports = 2201,
  ReadAllReports = 2202,
  ReportsEdit = 2203,
  ModulesChange = 2204,

  IdentityUsersRead = 32000,
  IdentityUsersUpdate = 32001,
  IdentityRolesRead = 32000,
  IdentityRolesUpdate = 32001,
  IdentityClientsRead = 32100,
  IdentityClientsUpdate = 32101,
  AccessAll = 32767,
}
