import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NavItem } from '../shared/widgets/menu-list-item/nav-item';
import { MainMenuItems } from './main-menu-items';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
})
export class MainMenuComponent implements OnInit {
  showNewOrder = false;
  items$: Observable<NavItem[]>;

  constructor(menuItems: MainMenuItems) {
    this.items$ = menuItems.getNavItems();
  }

  ngOnInit(): void {}
}
