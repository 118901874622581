import { Action, createReducer, on } from '@ngrx/store';
import { OperationDto } from '../../shared/models/operation.dto';
import { loadOperation, loadOperationFailure, loadOperationSuccess } from './operation.actions';

export const operationFeatureKey = 'operation';

export interface State {
  operation?: OperationDto;
}

export const initialState: State = {};

const operationReducer = createReducer(
  initialState,

  on(loadOperation, state => setOperation(state, null)),
  on(loadOperationSuccess, (state, { data }) => setOperation(state, data)),
  on(loadOperationFailure, (state, action) => setOperation(state, null))
);

function setOperation(state: State, data: OperationDto) {
  return {
    ...state,
    operation: data
  };
}

export function reducer(state: State | undefined, action: Action) {
  return operationReducer(state, action);
}
