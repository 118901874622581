import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { FileSaverModule } from 'ngx-filesaver';
import { InputComponent } from './input/input.component';
import { MenuListItemComponent } from './menu-list-item/menu-list-item.component';
import { SelectSearchComponent } from './select-search/select-search.component';
import { SpinnerButtonComponent } from './spinner-button/spinner-button.component';
import { LoaderSpinnerComponent } from './loader-spinner/loader-spinner.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { MaterialModule } from '../material.module';
import { BussinesIndividualFormComponent } from './forms/bussines-individual-form/bussines-individual-form.component';
import { PipesModule } from '../pipes/pipes.module';
import { DictionaryListComponent, DictionaryAddValueDialog } from './dictionary-list/dictionary-list.component';
import { DialogConfirmComponent } from './dialog-confirm/dialog-confirm.component';
import { DirectivesModule } from '../directives/directives.module';
import { SnackBarMessagesComponent } from './snack-bar-messages/snack-bar-messages.component';

@NgModule({
  declarations: [
    SelectSearchComponent,
    InputComponent,
    SpinnerButtonComponent,
    MenuListItemComponent,
    LoaderSpinnerComponent,
    AutocompleteComponent,
    BussinesIndividualFormComponent,
    DictionaryListComponent,
    DictionaryAddValueDialog,
    DialogConfirmComponent,
    SnackBarMessagesComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    FontAwesomeModule,
    NgSelectModule,
    FileSaverModule,
    MaterialModule,
    PipesModule,
    DirectivesModule,
  ],
  exports: [
    SelectSearchComponent,
    InputComponent,
    SpinnerButtonComponent,
    MenuListItemComponent,
    LoaderSpinnerComponent,
    AutocompleteComponent,
    BussinesIndividualFormComponent,
    DictionaryListComponent,
    PipesModule,
  ],
  entryComponents: [
    LoaderSpinnerComponent,
    DialogConfirmComponent,
    DictionaryAddValueDialog,
    SnackBarMessagesComponent,
  ],
})
export class WidgetsModule {}
