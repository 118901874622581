import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { loadOperation, loadOperationFailure, loadOperationSuccess } from './operation.actions';

@Injectable()
export class OperationEffects {
  // operation$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(loadOperation),
  //     concatMap(({ id }) => {
  //       return this.operation(id).pipe(
  //         map(_ => loadOperationSuccess({ data: _ })),
  //         catchError(error => of(loadOperationFailure({ error })))
  //       );
  //     })
  //   )
  // );

  // private get data$() {
  //   return of(operationsSeed);
  // }

  // private operation(id) {
  //   const data = operationsSeed.find(c => c.id.equals(id));
  //   return of(data);
  // }

  constructor(private actions$: Actions) {}
}
