import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiCaller } from './base-api-caller';

@Injectable({
  providedIn: 'root'
})
export class InfoApiCallerService extends BaseApiCaller {
  protected controllerPath = 'info';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getVersion() {
    return this.get<string>('');
  }
}
