import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.scss'],
})
export class DialogConfirmComponent implements OnInit, OnDestroy {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, public dialogRef: MatDialogRef<any>) {}

  ngOnDestroy() {}
  ngOnInit() {
    if (this.data.confirmButtonText == null) {
      this.data.confirmButtonText = 'Tak';
    }
    if (this.data.cancelButtonText == null) {
      this.data.cancelButtonText = 'Nie';
    }
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  ok() {
    this.dialogRef.close('ok');
  }
}

export interface DialogData {
  title: string;
  description: string;
  confirmButtonText: string;
  cancelButtonText: string;
}
