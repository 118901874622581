import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Route,
  Router,
  RouterStateSnapshot,
  UrlTree,
  CanLoad,
} from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, combineLatest } from 'rxjs';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { SecurityService } from '../services/security.service';
import { AppPermission } from '../models/enums/app-permission.enum';
import { SnackBarWrapperService } from '../services/snack-bar-wrapper.service';
import { permissionDenied } from '../services/snack-bar-messaged';

export class RouteData {
  permissions: AppPermission[];

  constructor(permissions: AppPermission[]) {
    this.permissions = permissions;
  }
}

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private snackBarWrapperService: SnackBarWrapperService,
    private router: Router,
    private oidcSecurityService: OidcSecurityService,
    private securityService: SecurityService
  ) {}

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.checkUser(route.data as RouteData);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkUser(route.data as RouteData);
  }

  canLoad(route: Route): Observable<boolean> {
    return this.checkUser(route.data as RouteData);
  }

  private checkUser(routeData?: RouteData): Observable<boolean> {
    if (routeData == null || routeData.permissions == null) {
      return this.oidcSecurityService.isAuthenticated$;
    } else {
      return combineLatest(
        this.oidcSecurityService.isAuthenticated$,
        this.securityService.hasClaims$(routeData.permissions)
      ).pipe(map(this.validateUser));
    }
  }

  hasClaims(claims: AppPermission[]) {
    return this.securityService.hasClaims(claims);
  }

  private validateUser = ([isAuthorized, hasClaims]) => {
    const result = isAuthorized && hasClaims;
    if (!result) {
      this.snackBarWrapperService.openMessage(permissionDenied);
    }

    return result;
  };
}
