import { Component, OnDestroy, OnInit } from '@angular/core';
import { version } from '../../../../package.json';
import { InfoApiCallerService } from '../shared/api-services/info-api-caller.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  version: string;

  constructor(private infoApiService: InfoApiCallerService) {}

  ngOnInit() {
    this.version = version;
  }
  ngOnDestroy() {}
}
