import { Injectable } from '@angular/core';

@Injectable()
export class NotificationsService {
  constructor() {}
  public showNotification(message: string, style: any, hideAfter: number = 2000): void {
    alert(message)
    // this.notificationService.show({
    //   content: message,
    //   cssClass: 'button-notification',
    //   animation: { type: 'fade', duration: 400 },
    //   position: { horizontal: 'center', vertical: 'bottom' },
    //   type: style,
    //   hideAfter: hideAfter,
    // });
  }

  public defaultSuccess(message = 'Zapisano') {
    this.showNotification(
      message,
      {
        style: 'success',
        icon: true,
      },
      1000
    );
  }
  public defaultError(message = 'Wystąpił błąd') {
    this.showNotification(message, {
      style: 'error',
      icon: true,
    });
  }
}
