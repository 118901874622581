import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StoreState } from '../../root-store/store-state';
import { selectClaimsDictionary, selectUserData } from '../../root-store/user-data/user-data-selectors';
import { PermissionDictionaryElement } from '../api-services/api-services';
import { ArrayHelper } from '../helpers/array-helper';
import { AppPermission } from '../models/enums/app-permission.enum';
import { UserDataDto } from '../models/user-data.dto';

@Injectable({
  providedIn: 'root',
})
export class SecurityService {
  userData: UserDataDto;
  allClaims: PermissionDictionaryElement[];

  constructor(private store$: Store<StoreState>) {
    this.store$
      .pipe(
        select(selectUserData),
        filter((_) => _ != null)
      )
      .subscribe((data) => {
        // dispatch for that is executed in authorized-layout component
        this.userData = data;
      });

    this.store$
      .pipe(
        select(selectClaimsDictionary),
        filter((_) => _ != null)
      )
      .subscribe((data) => {
        this.allClaims = data;
      });
  }

  hasClaim(claimType: AppPermission): boolean {
    const claimValue = this.allClaims.filter((obj) => {
      return obj.value === claimType;
    });
    const accessAll = this.allClaims.filter((obj) => {
      return obj.name === 'AccessAll';
    });
    if (
      (claimValue.length > 0 && this.userData.enumPermission.includes(claimValue[0].value)) ||
      this.userData.enumPermission.includes(accessAll[0].value)
    ) {
      return true;
    } else {
      return false;
    }
  }

  hasClaims$(claims: AppPermission[], respectAccessAll = true): Observable<boolean> {
    return this.store$.pipe(
      select(selectClaimsDictionary),
      filter((_) => _ != null),
      map((allClaims) => {
        return this.checkClaims(allClaims, claims, respectAccessAll);
      })
    );
  }

  hasClaims(claims: AppPermission[], respectAccessAll = true): boolean {
    return this.checkClaims(this.allClaims, claims, respectAccessAll);
  }

  private checkClaims(
    allClaims: PermissionDictionaryElement[],
    claims: AppPermission[],
    respectAccessAll = true
  ): boolean {
    if (allClaims == null || this.userData == null) {
      return false;
    }
    if (respectAccessAll) {
      const accessAll = allClaims.filter((obj) => {
        return obj.name === 'AccessAll';
      });
      if (this.userData.enumPermission.includes(accessAll[0].value)) {
        return true;
      }
    }

    const claimsValues = allClaims.filter((obj) => {
      return claims.includes(obj.value);
    });

    const result = ArrayHelper.arraysIntersects(
      claimsValues.map((_) => _.value),
      this.userData.enumPermission
    );

    return result;
  }
}
