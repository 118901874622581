import { Component, HostBinding, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NavService } from './nav.service';
import { NavItem } from './nav-item';

@Component({
  selector: 'app-menu-list-item',
  templateUrl: './menu-list-item.component.html',
  styleUrls: ['./menu-list-item.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4,0.0,0.2,1)'))
    ])
  ]
})
export class MenuListItemComponent implements OnInit {
  expanded: boolean;
  isActive: boolean;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() item: NavItem;
  @Input() depth: number;

  constructor(public navService: NavService, public router: Router) {
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }

  ngOnInit() {
  
    this.navService.change.subscribe( () => {
      this.expanded = false;
    });

    this.navService.refresh.subscribe( () => {
      this.isElementActive();
    });
  }

  ngAfterContentInit() {
    this.isElementActive();
  }

  isElementActive(){
    if(this.router.isActive(this.item.route, true)){
      this.isActive = true;
    }
    else{
      if(this.item.children != undefined){
        if(this.hasActiveChild()){
          this.isActive = true;
        }
        else{
          this.isActive = false;
        }
      }
      else{
        this.isActive = false;
      }
    }
  }

  hasActiveChild(): boolean{
    var response: boolean = false;
    this.item.children.forEach(child => {
      if(this.router.isActive(child.route, true)){
        response = true;
        return;
      }
    });
    return response;
  }

  onItemSelected(item: NavItem) {
    if (!item.children || !item.children.length) {
      this.router.navigate([item.route]).then(
        () =>{
          //this.navService.closeNav();
          //this.navService.retractAllTabs();
          this.navService.refreshAllTabs();
        });
      }
    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
    }
  }
}
