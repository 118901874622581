<div>
  <mat-form-field>
    <mat-label>{{label}}</mat-label>
    <mat-select [formControl]="searchForm" (selectionChange)="selectionChanged($event)" multiple>
      <mat-option *ngFor="let grantType of items" [value]="grantType">{{grantType}}</mat-option>
    </mat-select>
    <div matSuffix class="display-flex">
      <!-- <mat-spinner *ngIf="loading" [diameter]="24"> </mat-spinner> -->
      <button mat-button type="button" (click)="onButtonClick($event)"><mat-icon>add</mat-icon></button>
    </div>
  </mat-form-field>
</div>