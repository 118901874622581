import { createAction, props } from '@ngrx/store';
import { ContractDto } from '../../shared/models/contract.dto';
import { Guid } from 'guid-typescript';

export const loadContracts = createAction('[Contract] Load Contracts');

export const loadContractsSuccess = createAction('[Contract] Load Contracts Success', props<{ data: ContractDto[] }>());

export const loadContractsFailure = createAction('[Contract] Load Contracts Failure', props<{ error: any }>());

export const loadContract = createAction('[Contract] Load Contract', props<{ id: Guid }>());

export const loadContractSuccess = createAction('[Contract] Load Contract Success', props<{ data: ContractDto }>());

export const loadContractFailure = createAction('[Contract] Load Contract Failure', props<{ error: any }>());
