import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarMessagesComponent } from '../widgets/snack-bar-messages/snack-bar-messages.component';

export type MessageType = 'success' | 'error';

@Injectable({
  providedIn: 'root',
})
export class SnackBarWrapperService {
  snackBarInstance: SnackBarMessagesComponent;

  constructor(private matSnackBar: MatSnackBar) {}

  public openMessage(message: string, type?: MessageType) {
    const panelClass = type == null ? undefined : this.getPanelClass(type);

    this.matSnackBar.openFromComponent(SnackBarMessagesComponent, {
      data: { message },
      duration: 20000,
      panelClass,
    });
  }

  private getPanelClass(type: MessageType): string | string[] {
    switch (type) {
      case 'success':
        return 'alert-success';
      case 'error':
        return 'alert-danger';
    }
  }

  public defaultSuccess(message: string = 'SnackBars.Saved') {
    const panelClass = this.getPanelClass('success');
    this.matSnackBar.openFromComponent(SnackBarMessagesComponent, {
      data: { message },
      duration: 2000,
      panelClass,
    });
  }

  public openSingletonMessage(message: string, type?: MessageType) {
    const panelClass = type == null ? undefined : this.getPanelClass(type);
    if (this.snackBarInstance) {
      this.snackBarInstance.errorMessage = message;
    } else {
      this.snackBarInstance = this.matSnackBar.openFromComponent(SnackBarMessagesComponent, {
        data: { message },
        duration: 5000,
        panelClass,
      }).instance;

      this.snackBarInstance.onClose.subscribe((val) => {
        this.snackBarInstance = null;
      });
    }
  }
}
