<div class="app-container" [class.is-mobile]="mobileQuery.matches">
  <app-header [className]="opened ? 'active' : 'inactive'">
    <div class="header-left">
      <button mat-icon-button (click)="snav.toggle()"><mat-icon>menu</mat-icon></button>
      <div class="logo-container">
        <a [routerLink]="['']">
          <h1 class="mb-0 app-title">{{ 'App.title' | translate }}</h1>
        </a>
      </div>
    </div>
    <div class="flex-spacer"></div>
    <div class="header-right mr-4">
      <div style="display: flex">
        <span style="color: black; margin-right: 10px" class="mr-2 align-middle">{{ user }}</span>
        <div *ngIf="isAuthenticated; else notLoggedButton">
          <button mat-button class="mat-primary" (click)="logout()">Wyloguj</button>
        </div>
        <ng-template #notLoggedButton>
          <button mat-button class="mat-primary" (click)="login()">Zaloguj</button>
        </ng-template>
      </div>
    </div>
  </app-header>

  <mat-sidenav-container class="sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav
      #snav
      [mode]="mobileQuery.matches ? 'over' : 'side'"
      [(opened)]="opened"
      [fixedInViewport]="mobileQuery.matches"
      fixedTopGap="56"
    >
      <div class="menu-container">
        <app-main-menu></app-main-menu>
        <div class="spacer"></div>
        <app-footer></app-footer>
      </div>
    </mat-sidenav>

    <mat-sidenav-content>
      <div class="shadow-box"></div>
      <div class="container-fluid">
        <div #spinner>
          <router-outlet></router-outlet>
        </div>
        <ng-template #notLoggedContent>
          <span>Nie jesteś zalogowany</span>
        </ng-template>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
