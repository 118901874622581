import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { filter } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { StoreState } from './root-store/store-state';
import { loadAllClaims, storeUserData } from './root-store/user-data/user-data.actions';

@Component({
  selector: 'app-maestro-app',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  public isAuthenticated = false;

  constructor(
    private oidcSecurityService: OidcSecurityService,
    translate: TranslateService,
    private router: Router,
    private store$: Store<StoreState>
  ) {
    translate.use(environment.lang);
    translate.get('App.title').subscribe((_) => (document.title = _));
  }

  ngOnInit() {
    this.oidcSecurityService
      .checkAuth()
      .pipe(filter((_) => _ != null))
      .subscribe((isAuthenticated) => {
        this.isAuthenticated = isAuthenticated;
        if (!isAuthenticated) {
          if ('#/autologin' !== window.location.hash) {
            this.router.navigate(['/autologin']);
          }
        }
      });

    this.oidcSecurityService.userData$.subscribe((userData) => {
      if (userData != null) {
        this.store$.dispatch(storeUserData({ data: userData }));
        const token = this.oidcSecurityService.getToken();
        if (token != null && token.length > 0) {
          this.store$.dispatch(loadAllClaims());
        }
      }
    });
  }
}
