import { Component, OnInit, ViewChild, Input, OnDestroy, forwardRef, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators, AbstractControl, ValidatorFn, ValidationErrors, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectModel } from '../../models/select-model';
import { Observable } from 'rxjs';
import { Guid } from 'guid-typescript';
import { LoaderSpinnerComponent } from '../loader-spinner/loader-spinner.component';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../dialog-confirm/dialog-confirm.component';

@Component({
  selector: 'app-dictionary-list',
  templateUrl: './dictionary-list.component.html',
  styleUrls: ['./dictionary-list.component.scss'],
  providers: [{       
    provide: NG_VALUE_ACCESSOR, 
    useExisting: forwardRef(() => DictionaryListComponent),
    multi: true     
}  ]
})
export class DictionaryListComponent implements OnInit, ControlValueAccessor {
  
  @Input() label: string = "Choose values";
  @Input() inputLabel: string = "Add new value";
  @Input() placeholder: string;
  @Input() formControlName;
  items: string[] = [];
  value: string[];


  inputValue: string = "";
  searchForm = new FormControl();

  constructor(public dialog: MatDialog) {
    
  }

  writeValue(obj: any): void {
    this.value = obj;
    if(obj != null){
      this.searchForm.patchValue(obj);
      this.items = [...obj];
    }
  }
  registerOnChange(fn: any): void {
    //throw new Error("Method not implemented.");
  }
  registerOnTouched(fn: any): void {
    //throw new Error("Method not implemented.");
  }
  setDisabledState?(isDisabled: boolean): void {
    //throw new Error("Method not implemented.");
  }

  ngOnInit() {
    this.validateInputs();
    //this.searchForm.setValidators(this.validateForm());
  }

  selectionChanged(event: MatOptionSelectionChange) {
    var eventData = event as any;
    this.value.splice(0,this.value.length)//clear array
    eventData.value.forEach(element => {
      this.value.push(element)
    });
  }

  addElement() {
    if(this.inputValue != null){
      this.items.push(this.inputValue);
      this.inputValue = null
    }
  }

  validateSearch() {
    this.searchForm.updateValueAndValidity();
  }

  private validateInputs() {
    
  }

  public onButtonClick(event: any){
    event.stopPropagation()
    console.log("button clicked")
    const dialogRef = this.dialog.open(DictionaryAddValueDialog, {
      width: '450px',
      //data: {name: "this.name", animal: "this.animal"}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined){
        this.items.push(result);
        this.value.push(result);
      }
    });
  }
}

@Component({
  selector: 'dictionary-add-value-dialog',
  template: `
  <h1 mat-dialog-title> {{'Common.AddValue' | translate}}</h1>
  <div mat-dialog-content>
    <mat-form-field>
      <mat-label>{{'Common.Value' | translate}}</mat-label>
      <input matInput [(ngModel)]="data">
    </mat-form-field>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button (click)="onNoClick()">{{'Common.Cancel' | translate}}</button>
    <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
  </div>
  `,
})
export class DictionaryAddValueDialog {
  constructor(
    public dialogRef: MatDialogRef<DictionaryAddValueDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}