import { DatePipe, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localePl from '@angular/common/locales/pl';
import { LOCALE_ID, NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EffectsModule } from '@ngrx/effects';
// vendor dependencies
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// Kendo UI
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
// environment
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthorisedLayoutComponent } from './areas/authorised-layout/authorised-layout.component';
import { GuestLayoutComponent } from './areas/guest-layout/guest-layout.component';
// app
import { Config } from './common/index';
import { FooterComponent } from './footer/footer.component';
// Components
import { MainMenuComponent } from './main-menu/main-menu.component';
import { RootStoreModule } from './root-store/root-store.module';
import { JwtInterceptor } from './shared/http-interceptors/jwt.interceptor';
import { SharedModule } from './shared/shared.module';
import { LoadingComponent } from './shared/spinner.component';
import { HeaderComponent } from './areas/header/header.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OidcConfigService, AuthModule } from 'angular-auth-oidc-client';
import { UnauthorizedComponent } from './areas/unauthorized/unauthorized.component';
import { AutoLoginComponent } from './areas/auto-login/auto-login.component';
import { API_BASE_URL } from './shared/api-services/api-services';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlPlService } from './shared/services/mat-paginator-intl-pl.service';

Config.PLATFORM_TARGET = Config.PLATFORMS.WEB;
registerLocaleData(localePl, 'pl');

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http as any, './assets/i18n/', '.json');
}
export function getBaseUrl() {
  return environment.apiRoot;
}

const oidc_configuration = 'assets/auth.clientConfiguration.json';

export function loadConfig(oidcConfigService: OidcConfigService) {
  return () => oidcConfigService.withConfig(environment.identityConfig);
}

@NgModule({
  declarations: [
    AppComponent,
    LoadingComponent,
    FooterComponent,
    AuthorisedLayoutComponent,
    GuestLayoutComponent,
    MainMenuComponent,
    HeaderComponent,
    UnauthorizedComponent,
    AutoLoginComponent,
  ],
  imports: [
    SweetAlert2Module.forRoot(),
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    BrowserModule,
    FlexLayoutModule,
    SharedModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    EffectsModule.forRoot([]),
    RootStoreModule,
    AuthModule.forRoot(),
  ],

  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'pl' },
    { provide: API_BASE_URL, useValue: environment.apiRoot },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlPlService },
    DatePipe,
    OidcConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfig,
      deps: [OidcConfigService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
