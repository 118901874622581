import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './reducers-index';
import { EffectsModule } from '@ngrx/effects';
import { OperationEffects } from './operations/operation.effects';
import { ContractEffects } from './contracts/contract.effects';
import { UserDataEffects } from './user-data/user-data.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    EffectsModule.forFeature([OperationEffects, ContractEffects, UserDataEffects])
  ]
})
export class RootStoreModule {}
