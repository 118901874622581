import {
  FlexibleConnectedPositionStrategyOrigin,
  Overlay,
  OverlayConfig,
  OverlayRef,
  PositionStrategy,
} from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { LoaderSpinnerComponent } from '../widgets/loader-spinner/loader-spinner.component';
import { getConnectionPositionsPair, OverlayPosition } from './overlay-positions';
 
export interface OverlayOptions {
  dom: FlexibleConnectedPositionStrategyOrigin;
  position: OverlayPosition;
  closeOnBackdrop?: boolean;
  hasBackdrop?: boolean;
}
 
@Injectable({
  providedIn: 'root',
})
export class OverlayWrapperService {
  private overlayRefs = new Map<string, OverlayRef>();
  public spinner : FlexibleConnectedPositionStrategyOrigin;

  constructor(private overlay: Overlay) {}
 
  public closeOverlay(key: string) {
    const overlayRef = this.overlayRefs.get(key);
    if (overlayRef != null) {
      overlayRef.detach();
      overlayRef.dispose();
      this.overlayRefs.delete(key);
    }
  }
 
  public openLoader(key: string, diameter = 240) {
    const component = this.openAttachedOverlay(key, LoaderSpinnerComponent, { dom : this.spinner, position: 'top', hasBackdrop: true })
      .componentRef;
    if (component != null && component.instance != null) {
      component.instance.diameter = diameter;
    }
 
    return component;
  }
 
  public openAttachedOverlay<T>(
    key: string,
    component: ComponentType<T> | ComponentPortal<T>,
    options: OverlayOptions
  ) {
    let overlayRef = this.overlayRefs.get(key);
    if (overlayRef != null) {
      return null;
    }
 
    overlayRef = this.overlay.create(this.getOverlayConfig(options));
    const componentRef = overlayRef.attach(
      component instanceof ComponentPortal ? component : new ComponentPortal(component)
    );
    this.overlayRefs.set(key, overlayRef);
 
    if (options.closeOnBackdrop) {
      overlayRef.backdropClick().subscribe(() => {
        this.closeOverlay(key);
      });
    }
 
    return { componentRef, overlayRef };
  }
 
  private getOverlayConfig(options: OverlayOptions): OverlayConfig {
    const config = new OverlayConfig({
      positionStrategy: this.getOverlayPosition(options.dom, options.position),
      hasBackdrop: options.closeOnBackdrop || options.hasBackdrop,
    });
 
    return config;
  }
 
  public getOverlayPosition(dom: FlexibleConnectedPositionStrategyOrigin, position: OverlayPosition): PositionStrategy {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(dom)
      .withPositions([getConnectionPositionsPair(position)])
      .withPush(false);
 
    return positionStrategy;
  }
}