import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { FileSaverModule } from 'ngx-filesaver';
import { NgxPrintModule } from 'ngx-print';
import { NgxSelectModule } from 'ngx-select-ex';
import { MaterialModule } from './material.module';
import { NotificationsService } from './notification.service';
import { WidgetsModule } from './widgets/widgets.module';
import { PipesModule } from './pipes/pipes.module';
import { ApiUsersApiService, ApiRolesApiService, ApiClientsApiService, ApiResourcesApiService, TestApiService } from './api-services/api-services';
import { IdentityApiServiceTEST } from './api-services/api-services_test';
import { HasClaimDirective } from './directives/has-claim.directive';
import { DirectivesModule } from './directives/directives.module';

@NgModule({
  imports: [CommonModule, FileSaverModule, NgSelectModule, NgxSelectModule, MaterialModule, DirectivesModule],
  declarations: [
  ],
  exports: [
    CdkTreeModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    WidgetsModule,
    NgxPrintModule,
    FileSaverModule,
    TranslateModule,
    NgxSelectModule,
    MaterialModule,
    PipesModule,
    HasClaimDirective
  ],
  providers: [
    NotificationsService,
    ApiUsersApiService,
    ApiRolesApiService,
    ApiClientsApiService, 
    ApiResourcesApiService,
    IdentityApiServiceTEST,
    TestApiService,
  ],
  entryComponents: []
})
export class SharedModule {}
