import { Action, createReducer, on } from '@ngrx/store';
import { ContractDto } from '../../shared/models/contract.dto';
import {
  loadContracts,
  loadContractsSuccess,
  loadContractsFailure,
  loadContract,
  loadContractSuccess,
  loadContractFailure
} from './contract.actions';

export const contractFeatureKey = 'contract';

export interface State {
  contract?: ContractDto;
  contracts?: ContractDto[];
}

export const initialState: State = {};

const contractReducer = createReducer(
  initialState,

  on(loadContract, state => setContract(state, null)),
  on(loadContractSuccess, (state, { data }) => setContract(state, data)),
  on(loadContractFailure, (state, action) => setContract(state, null)),

  on(loadContracts, state => setContracts(state, null)),
  on(loadContractsSuccess, (state, { data }) => setContracts(state, data)),
  on(loadContractsFailure, (state, action) => setContracts(state, null))
);

function setContract(state, data) {
  return { ...state, contract: data };
}

function setContracts(state, data) {
  return { ...state, contracts: data };
}

export function reducer(state: State | undefined, action: Action) {
  return contractReducer(state, action);
}
