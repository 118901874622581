import {
  Component,
  OnInit,
  HostBinding,
  HostListener,
  ViewEncapsulation,
  OnDestroy,
  ChangeDetectorRef,
  ElementRef,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AuthenticationService } from '../../shared/api-services/authentication-service.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { NavService } from '../../shared/widgets/menu-list-item/nav.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Store } from '@ngrx/store';
import { StoreState } from '../../root-store/store-state';
import { storeUserData, loadAllClaims } from '../../root-store/user-data/user-data.actions';
import { OverlayWrapperService } from '../../shared/overlay/overlay-wrapper.service';

@Component({
  selector: 'app-authorised-layout',
  templateUrl: './authorised-layout.component.html',
  styleUrls: ['./authorised-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthorisedLayoutComponent implements OnInit, OnDestroy {
  @ViewChild("spinner", {static:true}) spinner;
  isAuthenticated: boolean;
  userData: any;

  @ViewChild('snav') snav: ElementRef;
  mobileQuery: MediaQueryList;
  public year = new Date().getFullYear();
  //public navState = 'expanded';
  public opened :boolean;
  user: string;
  constructor(
    private router: Router,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private navService: NavService,
    private overlayService: OverlayWrapperService,
    public oidcSecurityService: OidcSecurityService,
    private store$: Store<StoreState>
  ) {
    

    this.opened = true

    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }
  ngOnInit(): void {
    this.oidcSecurityService.checkAuth().subscribe((auth) => {
      this.isAuthenticated = auth;
    });
    this.overlayService.spinner = this.spinner;

    this.oidcSecurityService.userData$.subscribe((auth) => {
      this.isAuthenticated = auth;
    });

    this.oidcSecurityService.userData$.subscribe((userData) => {
      if (userData != null) {
        this.userData = userData;
        this.user = userData.given_name[0] + " " + userData.family_name[0];
        this.store$.dispatch(storeUserData({ data: this.userData }));
      }
    });
  }

  ngAfterViewInit() {
    this.navService.appDrawer = this.snav;
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    
  }

  public showNav() {
    return this.router.url !== '/signin';
  }

  login() {
    this.oidcSecurityService.authorize();
  }

  logout() {
    this.oidcSecurityService.logoff();
  }

  private _mobileQueryListener: () => void;
}
