import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IndividualType } from '../../../models/enums/individual-type.enum';

@Component({
  selector: 'app-bussines-individual-form',
  templateUrl: './bussines-individual-form.component.html',
  styleUrls: ['./bussines-individual-form.component.scss']
})
export class BussinesIndividualFormComponent implements OnInit {
  @Input() form: FormGroup;
  individualType = IndividualType;

  constructor() {}

  ngOnInit() {}
}
