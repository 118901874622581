// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// import { OpenIdConfiguration, LogLevel } from 'angular-auth-oidc-client';

// export const environment = {
//   production: true,
//   //apiRoot: 'http://pet-pesel.identity.devitmcode.pl',
//   apiRoot: 'https://localhost:44380',
//   lang: 'pl',
//   identityConfig: {
//   //  stsServer: 'http://pet-pesel.identity.devitmcode.pl',
//    stsServer: 'https://localhost:44380',
//     redirectUrl: 'http://localhost:4200/#/authorised/dashboard',
//     clientId: 'identity_client_local',
//     responseType: 'code',
//     scope: 'IdentityServerApi openid offline_access profile',
//     postLogoutRedirectUri: 'http://localhost:4200/#/authorised/dashboard',
//     silentRenew: true,
//     silentRenewUrl: `${window.location.origin}/assets/silent-renew.html`,
//     renewTimeBeforeTokenExpiresInSeconds: 15,
//     postLoginRoute: '/home',
//     forbiddenRoute: '/forbidden',
//     unauthorizedRoute: '/unauthorized',
//     logLevel: LogLevel.Error,
//     useRefreshToken: true,
//     storage: localStorage,
//     autoUserinfo: true,
//   } as OpenIdConfiguration,
// };

// import { OpenIdConfiguration, LogLevel } from 'angular-auth-oidc-client';

// export const environment = {
//   production: false,
//   apiRoot: 'http://localhost:40100',
//   lang: 'pl',
//   identityConfig: {
//     stsServer: 'http://localhost:40100',
//     redirectUrl: 'http://localhost:4200/#/authorised/dashboard',
//     clientId: 'identity_client_local',
//     responseType: 'code',
//     scope: 'IdentityServerApi openid offline_access profile',
//     postLogoutRedirectUri: 'http://localhost:4200/#/authorised/dashboard',
//     silentRenew: true,
//     silentRenewUrl: `${window.location.origin}/assets/silent-renew.html`,
//     renewTimeBeforeTokenExpiresInSeconds: 15,
//     postLoginRoute: '/home',
//     forbiddenRoute: '/forbidden',
//     unauthorizedRoute: '/unauthorized',
//     logLevel: LogLevel.Error,
//     useRefreshToken: true,
//     storage: localStorage,
//     autoUserinfo: true,
//   } as OpenIdConfiguration,
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

// import { OpenIdConfiguration, LogLevel } from 'angular-auth-oidc-client';

// export const environment = {
//   production: true,
//   apiRoot: 'https://identity.petpesel.pl',
//  //apiRoot: 'https://localhost:44380',
//   lang: 'pl',
//   identityConfig: {
//     stsServer: 'https://identity.petpesel.pl',
//    //stsServer: 'https://localhost:44380',
//     redirectUrl: 'https://control-panel.petpesel.pl/#/authorised/dashboard',
//     clientId: 'pet-pesel-identity-client',
//     responseType: 'code',
//     scope: 'IdentityServerApi openid offline_access profile',
//     postLogoutRedirectUri: 'https://control-panel.petpesel.pl/#/authorised/dashboard',
//     silentRenew: true,
//     silentRenewUrl: `${window.location.origin}/assets/silent-renew.html`,
//     renewTimeBeforeTokenExpiresInSeconds: 15,
//     postLoginRoute: '/home',
//     forbiddenRoute: '/forbidden',
//     unauthorizedRoute: '/unauthorized',
//     logLevel: LogLevel.Error,
//     useRefreshToken: true,
//     storage: localStorage,
//     autoUserinfo: true,
//   } as OpenIdConfiguration,
// };

// import { OpenIdConfiguration, LogLevel } from 'angular-auth-oidc-client';

// export const environment = {
//   production: true,
//   apiRoot: 'https://ticket.identity.devitmcode.pl',
//   //apiRoot: "https://localhost:44380",
//   lang: 'pl',
//   identityConfig: {
//   //  stsServer: 'http://pet-pesel.identity.devitmcode.pl',
//    stsServer: "https://ticket.identity.devitmcode.pl",
//     redirectUrl: 'http://localhost:4200/#/authorised/dashboard',
//     clientId: 'identity_client',
//     responseType: 'code',
//     scope: 'IdentityServerApi openid offline_access profile',
//     postLogoutRedirectUri: 'http://localhost:4200/#/authorised/dashboard',
//     silentRenew: true,
//     silentRenewUrl: `${window.location.origin}/assets/silent-renew.html`,
//     renewTimeBeforeTokenExpiresInSeconds: 15,
//     postLoginRoute: '/home',
//     forbiddenRoute: '/forbidden',
//     unauthorizedRoute: '/unauthorized',
//     logLevel: LogLevel.Error,
//     useRefreshToken: true,
//     storage: localStorage,
//     autoUserinfo: true,
//   } as OpenIdConfiguration,
// };
// import { OpenIdConfiguration, LogLevel } from 'angular-auth-oidc-client';


// export const environment = {
//   production: true,
//   //apiRoot: 'http://pet-pesel.identity.devitmcode.pl',
//   apiRoot: "https://zusticket.identity.devitmcode.pl",
//   lang: 'pl',
//   identityConfig: {
//   //  stsServer: 'http://pet-pesel.identity.devitmcode.pl',
//    stsServer: "https://zusticket.identity.devitmcode.pl",
//     redirectUrl: 'http://localhost:4200/#/authorised/dashboard',
//     clientId: 'identity_client',
//     responseType: 'code',
//     scope: 'IdentityServerApi openid offline_access profile',
//     postLogoutRedirectUri: 'http://localhost:4200/#/authorised/dashboard',
//     silentRenew: true,
//     silentRenewUrl: `${window.location.origin}/assets/silent-renew.html`,
//     renewTimeBeforeTokenExpiresInSeconds: 15,
//     postLoginRoute: '/home',
//     forbiddenRoute: '/forbidden',
//     unauthorizedRoute: '/unauthorized',
//     logLevel: LogLevel.Error,
//     useRefreshToken: true,
//     storage: localStorage,
//     autoUserinfo: true,
//   } as OpenIdConfiguration,
// };

 import { OpenIdConfiguration, LogLevel } from 'angular-auth-oidc-client';


// export const environment = {
//   production: true,
//   //apiRoot: 'http://pet-pesel.identity.devitmcode.pl',
//   apiRoot: "https://concrete.identity.devitmcode.pl",
//   lang: 'pl',
//   identityConfig: {
//   //  stsServer: 'http://pet-pesel.identity.devitmcode.pl',
//    stsServer: "https://concrete.identity.devitmcode.pl",
//     redirectUrl: 'http://localhost:4200/#/authorised/dashboard',
//     clientId: 'identity_client',
//     responseType: 'code',
//     scope: 'IdentityServerApi openid offline_access profile',
//     postLogoutRedirectUri: 'http://localhost:4200/#/authorised/dashboard',
//     silentRenew: true,
//     silentRenewUrl: `${window.location.origin}/assets/silent-renew.html`,
//     renewTimeBeforeTokenExpiresInSeconds: 15,
//     postLoginRoute: '/home',
//     forbiddenRoute: '/forbidden',
//     unauthorizedRoute: '/unauthorized',
//     logLevel: LogLevel.Error,
//     useRefreshToken: true,
//     storage: localStorage,
//     autoUserinfo: true,
//   } as OpenIdConfiguration,
// };
//ticket_panel_prod


export const environment = {
  production: true,
  apiRoot: 'https://ticket.identity.devitmcode.pl',
  //apiRoot: "https://localhost:44380",
  lang: 'pl',
  identityConfig: {
  //  stsServer: 'http://pet-pesel.identity.devitmcode.pl',
   stsServer: "https://ticket.identity.devitmcode.pl",
    redirectUrl: 'https://ticket.panel.devitmcode.pl/#/authorised/dashboard',
    clientId: 'ticket_panel_prod',
    responseType: 'code',
    scope: 'IdentityServerApi openid offline_access profile',
    postLogoutRedirectUri: 'http://ticket.panel.devitmcode.pl/#/authorised/dashboard',
    silentRenew: true,
    silentRenewUrl: `${window.location.origin}/assets/silent-renew.html`,
    renewTimeBeforeTokenExpiresInSeconds: 15,
    postLoginRoute: '/home',
    forbiddenRoute: '/forbidden',
    unauthorizedRoute: '/unauthorized',
    logLevel: LogLevel.Error,
    useRefreshToken: true,
    storage: localStorage,
    autoUserinfo: true,
  } as OpenIdConfiguration,
};