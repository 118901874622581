<form class="display-grid" [formGroup]="form">
  <mat-form-field>
    <mat-label>{{ 'Common.Name' | translate }}</mat-label>
    <input matInput formControlName="name" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'Common.ShortName' | translate }}</mat-label>
    <input matInput formControlName="shortName" />
  </mat-form-field>
  <div class="height-30"></div>

  <mat-card-subtitle>{{ 'PersonalData.Title' | translate }}</mat-card-subtitle>
  <mat-form-field>
    <mat-label>{{ 'PersonalData.FirstName' | translate }}</mat-label>
    <input matInput formControlName="firstName" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'PersonalData.LastName' | translate }}</mat-label>
    <input matInput formControlName="lastName" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'PersonalData.Post' | translate }}</mat-label>
    <input matInput formControlName="post" />
  </mat-form-field>
  <div class="height-30"></div>

  <mat-card-subtitle>{{ 'PersonalData.Identifications' | translate }}</mat-card-subtitle>
  <mat-radio-group aria-label="Select an option" formControlName="individualType">
    <mat-radio-button [value]="individualType.LegalEntity">{{
      'PersonalData.LegalEntity' | translate
    }}</mat-radio-button>
    <mat-radio-button [value]="individualType.PrivateIndividual">{{
      'PersonalData.PrivateIndividual' | translate
    }}</mat-radio-button>
  </mat-radio-group>
  <ng-container [ngSwitch]="form.value.individualType">
    <ng-container *ngSwitchCase="individualType.PrivateIndividual">
      <mat-form-field>
        <mat-label>{{ 'PersonalData.PersonalIdentityNumber' | translate }}</mat-label>
        <input
          matInput
          formControlName="personalIdentityNumber"
          [placeholder]="'PersonalData.EnterFormat' | translate: { format: 'xxxxxxxxxxx' }"
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'PersonalData.IdentityDocument' | translate }}</mat-label>
        <input
          matInput
          formControlName="identityDocument"
          [placeholder]="'PersonalData.EnterFormat' | translate: { format: 'YYYxxxxxx' }"
        />
      </mat-form-field>
    </ng-container>

    <ng-container *ngSwitchCase="individualType.LegalEntity">
      <mat-form-field>
        <mat-label>{{ 'PersonalData.TaxNumber' | translate }}</mat-label>
        <input
          matInput
          formControlName="taxNumber"
          [placeholder]="'PersonalData.EnterFormat' | translate: { format: 'xxxxxxxxxx' }"
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'PersonalData.NationalCourtRegister' | translate }}</mat-label>
        <input
          matInput
          formControlName="nationalCourtRegister"
          [placeholder]="'PersonalData.EnterFormat' | translate: { format: 'xxxxxxxxxx' }"
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'PersonalData.Regon' | translate }}</mat-label>
        <input
          matInput
          formControlName="regon"
          [placeholder]="'PersonalData.EnterFormat' | translate: { format: 'xxxxxxxxx' }"
        />
      </mat-form-field>
    </ng-container>
  </ng-container>
</form>
