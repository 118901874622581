import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { contractsSeed } from '../../contracts/contracts-seed';
import {
  loadContracts,
  loadContractsSuccess,
  loadContractsFailure,
  loadContract,
  loadContractSuccess,
  loadContractFailure
} from './contract.actions';

@Injectable()
export class ContractEffects {
  contract$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadContract),
      concatMap(({ id }) => {
        return this.contract(id).pipe(
          map(_ => loadContractSuccess({ data: _ })),
          catchError(error => of(loadContractFailure({ error })))
        );
      })
    )
  );

  contracts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadContracts),
      concatMap(() => {
        return this.data$.pipe(
          map(_ => loadContractsSuccess({ data: _ })),
          catchError(error => of(loadContractsFailure({ error })))
        );
      })
    )
  );

  private get data$() {
    return of(contractsSeed);
  }

  private contract(id) {
    const data = contractsSeed.find(c => c.id.equals(id));
    return of(data);
  }

  constructor(private actions$: Actions) {}
}
