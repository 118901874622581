import { HttpClient } from '@angular/common/http';
import { environment } from 'project/src/environments/environment';
import { Observable } from 'rxjs';
import { ResponseDto } from '../models/response-dto';
import { map, tap } from 'rxjs/operators';

export abstract class BaseApiCaller {
  protected abstract controllerPath: string;

  constructor(private httpClient: HttpClient) {}

  protected get<T>(path: string): Observable<T> {
    return this.httpClient.get<ResponseDto<T>>(this.getFullPath(path)).pipe(
      tap(_ => this.checkError(_)),
      map(_ => _.result)
    );
  }

  protected post<T>(path: string, body: any): Observable<T> {
    return this.httpClient.post<ResponseDto<T>>(this.getFullPath(path), body).pipe(
      tap(_ => this.checkError(_)),
      map(_ => _.result)
    );
  }

  private getFullPath(path: string) {
    return environment.apiRoot + '/api/' + this.controllerPath + '/' + path;
  }

  private checkError(response: ResponseDto<any>) {
    if (response.isError) {
      throw new Error('Error with request');
    }
  }
}
