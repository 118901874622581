import { ContractDto } from '../shared/models/contract.dto';
import { Guid } from 'guid-typescript';

export const contractsSeed: ContractDto[] = [
  {
    id: Guid.parse('44a08be3-336e-3ea4-442c-688215bca631'),
    name: 'Kontrakt #1',
    company: 'Impel',
    client: null
  },
  {
    id: Guid.parse('44a08be3-336e-3ea4-442c-688215bca632'),
    name: 'Kontrakt #2',
    company: 'Impel',
    client: null
  }
];
