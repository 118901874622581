import { FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css']
})
export class InputComponent implements OnInit {
  @Input() form: FormGroup;
  formControl: AbstractControl;
  @Input() inputType: string = null;
  @Input() autocomplete: string = undefined;

  @Input() set formControlString(val: string) {
    this.formControl = this.form.get(val);
  }
  @Input() label: string;

  constructor() {}

  ngOnInit() {}
}
