import { Routes } from '@angular/router';
import { AuthorisedLayoutComponent } from './areas/authorised-layout/authorised-layout.component';
import { GuestLayoutComponent } from './areas/guest-layout/guest-layout.component';
import { AuthGuard } from './shared/helpers/auth.guard';
import { UnauthorizedComponent } from './areas/unauthorized/unauthorized.component';
import { AutoLoginComponent } from './areas/auto-login/auto-login.component';

export const AppRoutes: Routes = [
  {
    path: 'authorised',
    component: AuthorisedLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        
        path: 'users',
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'roles',
        loadChildren: () => import('./roles/roles.module').then(m => m.RolesModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'clients',
        loadChildren: () => import('./clients/clients.module').then(m => m.ClientsModule)
      },
      {
        canActivate: [AuthGuard],
        path: 'resources',
        loadChildren: () => import('./resources/resources.module').then(m => m.ResourcesModule)
      },
      { 
        canActivate: [AuthGuard],
        path: 'contracts',
        loadChildren: () => import('./contracts/contracts.module').then(m => m.ContractsModule) 
      },
      { path: '', pathMatch: 'full', redirectTo: 'operations/catalog' }
    ]
  },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'autologin', component: AutoLoginComponent },
  { path: '**', redirectTo: 'authorised' }
];
