import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../environments/environment';
import { StoreState } from './store-state';
import { reducer as operations } from './operations/operation.reducer';
import { reducer as contracts } from './contracts/contract.reducer';
import { reducer as userData } from './user-data/user-data.reducer';

export const reducers: ActionReducerMap<StoreState> = {
  operations,
  contracts,
  userData,
};

export const metaReducers: MetaReducer<StoreState>[] = !environment.production ? [] : [];
