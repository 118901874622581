import { NavItem } from '../shared/widgets/menu-list-item/nav-item';
import { Injectable, OnDestroy } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';
import { AuthenticationService } from '../shared/api-services/authentication-service.service';
import { cloneDeep } from 'lodash';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Actions, ofType } from '@ngrx/effects';
import { SecurityService } from '../shared/services/security.service';
import { loadAllClaimsSuccess } from '../root-store/user-data/user-data.actions';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { AppPermission } from '../shared/models/enums/app-permission.enum';

@Injectable({ providedIn: 'root' })
export class MainMenuItems implements OnDestroy {
  private items$: BehaviorSubject<NavItem[]>;

  constructor(
    private securityService: SecurityService,
    public oidcSecurityService: OidcSecurityService,
    updates$: Actions
  ) {
    this.items$ = new BehaviorSubject<NavItem[]>(this.getFiltered());

    updates$.pipe(ofType(loadAllClaimsSuccess)).subscribe(() => {
      this.items$.next(this.getFiltered());
    });
  }
  ngOnDestroy(): void {}

  public getNavItems(): Observable<NavItem[]> {
    return this.items$.asObservable();
  }

  private getFiltered() {
    const items = cloneDeep(navItems);
    const result = this.filterForClaims(items);

    return result;
  }

  private filterForClaims(items: NavItem[]) {
    const filtered = items.filter(
      (_) => _.claims == null || this.securityService.hasClaims(_.claims, _.strictClaims !== true)
    );

    filtered.forEach((i) => {
      if (i.children != null) {
        i.children = this.filterForClaims(i.children);
      }
    });

    return filtered;
  }
}

const navItems: NavItem[] = [
  {
    displayName: 'Użytkownicy',
    iconName: 'person_outline',
    claims: [AppPermission.IdentityUsersRead],
    route: '/authorised/users/catalog',
  },
  {
    displayName: 'Role',
    iconName: 'business',
    claims: [AppPermission.IdentityRolesRead],
    route: '/authorised/roles/catalog',
  },
  {
    displayName: 'Klienci',
    disabled: false,
    iconName: 'person_outline',
    claims: [AppPermission.IdentityClientsRead],
    route: '/authorised/clients/catalog',
  },
  {
    displayName: 'Zasoby',
    iconName: 'business',
    claims: [AppPermission.IdentityClientsRead],
    route: '/authorised/resources/catalog',
  },
];
