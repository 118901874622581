export enum IndividualType {
  LegalEntity,
  PrivateIndividual
}

export class IndividualTypeString {
  map = new Map<IndividualType, string>([
    [IndividualType.LegalEntity, 'Osoba prawna'],
    [IndividualType.PrivateIndividual, 'Osoba prywatna']
  ]);

  static get(item: IndividualType) {
    const result = new IndividualTypeString().map.get(item);

    return result;
  }
}
